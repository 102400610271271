import { useEffect, useRef } from "react";
import Link from "next/link";
import { CaretLeft, CopySimple, Robot, WarningCircle } from "phosphor-react";
import { twMerge } from "tailwind-merge";
import { Button } from "../button/Button";
import { ForaLogo } from "../ForaLogo";
import { TooltipWrapper } from "../TooltipWrapper";
import { NavLinkList } from "./NavLinkList";
import { isLinkActive, useNavItems } from "./useNavItems";
import { URI_CHATBOT, URI_HOME } from "constants/urls";
import { useModalManager } from "hooks/use-modal-manager";
import { trackReportIssueClicked } from "analytics/issues/report-issue-analytics";
import { IATA } from "constants/index";

/** A styled button with an icon for the bottom of the nav */
const FooterButton = (
    props: React.ButtonHTMLAttributes<HTMLButtonElement>
): JSX.Element => (
    <button
        {...props}
        className="inline-flex gap-x-1 px-3 py-2 items-center justify-center rounded-xl text-nowrap text-xs whitespace-nowrap bg-nav-hover hover:bg-nav-active text-nav-textSubdued font-semibold focus-visible:!outline focus-visible:!outline-2"
    />
);

type SideNavProps = {
    isOpen: boolean;
    hideNavbar: () => void;
};

/** The main side-nav for the Advisor Portal */
export const SideNav = ({ isOpen, hideNavbar }: SideNavProps): JSX.Element => {
    const navItems = useNavItems();

    const navRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (!isOpen || !navRef.current) return;
        const clickOutsideClose = (e: MouseEvent) => {
            if (navRef.current?.contains(e.target as Node)) return;
            hideNavbar();
        };
        document.addEventListener("mousedown", clickOutsideClose);
        return () =>
            document.removeEventListener("mousedown", clickOutsideClose);
    }, [isOpen]);

    const sidekickActive = isLinkActive(URI_CHATBOT);

    const onClickCopyIATA = () => navigator.clipboard.writeText(IATA);

    const { openModal } = useModalManager();
    const onClickReportIssue = () => {
        if (isOpen) hideNavbar();
        openModal("report-issue");
        trackReportIssueClicked();
    };

    return (
        <nav
            ref={navRef}
            className="grid grid-rows-[min-content_auto_min-content] lg:w-64 h-full bg-nav-bg border-r border-solid border-nav-brand"
        >
            <div className="flex justify-between items-center pl-8 pr-4 py-4 lg:px-[27px] lg:py-6">
                <Link href={URI_HOME} prefetch={false}>
                    <ForaLogo
                        data-testid="logo-image-id"
                        className="h-6 w-20"
                    />
                </Link>
                <Button
                    className="lg:hidden"
                    variant="text"
                    PhosphorIcon={CaretLeft}
                    onClick={hideNavbar}
                />
            </div>
            <NavLinkList
                items={navItems.top}
                className="px-4 pt-1 pb-4 overflow-y-auto"
            />
            <div className="flex flex-col gap-y-4 pb-5 mt-auto px-4">
                <NavLinkList
                    items={navItems.bottom}
                    className="pt-4 border-t border-solid border-nav-brand"
                />
                <div className="grid grid-cols-2 gap-2">
                    <Link
                        className={twMerge(
                            "col-span-2 rounded-xl p-[1px] bg-nav-button text-nav-textSubdued rainbow-hover-bg",
                            sidekickActive &&
                                "bg-nav-textMain text-nav-textMain"
                        )}
                        href={URI_CHATBOT}
                        prefetch={false}
                    >
                        <div className="inline-flex items-center bg-nav-bgLight rounded-xl gap-x-2 py-1 px-[10px] text-sm font-semibold w-full h-full min-h-7 leading-[8px]">
                            <Robot
                                size={20}
                                weight={sidekickActive ? "duotone" : undefined}
                            />
                            Ask Sidekick
                        </div>
                    </Link>
                    <TooltipWrapper
                        content="Copied!"
                        delayHide={1500}
                        openOnClick
                    >
                        <FooterButton onClick={onClickCopyIATA}>
                            Copy IATA
                            <CopySimple />
                        </FooterButton>
                    </TooltipWrapper>
                    <FooterButton onClick={onClickReportIssue}>
                        Report Issue
                        <WarningCircle />
                    </FooterButton>
                </div>
            </div>
        </nav>
    );
};
