import { useState } from "react";
import { twMerge } from "tailwind-merge";
import Link, { LinkProps } from "next/link";
import { ArrowSquareOut, CaretDown, Folder } from "phosphor-react";
import { Icon as PhosphorIconT } from "phosphor-react";
import { Transition } from "@headlessui/react";

interface StyledLinkProps
    extends LinkProps,
        Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> {
    active?: boolean;
}

/** A Link component with base nav styling */
const StyledLink = ({
    active,
    prefetch = false,
    className,
    ...props
}: StyledLinkProps): JSX.Element => (
    <Link
        tabIndex={0}
        className={twMerge(
            "p-2 flex gap-x-2 items-center rounded-xl text-base text-nav-textSubdued font-semibold bg-nav-bg hover:bg-nav-hover focus-visible:!outline focus-visible:!outline-2",
            active && "bg-nav-active text-nav-textMain",
            className
        )}
        prefetch={prefetch}
        {...props}
    />
);

type NavItemBase = {
    label: string;
    PhosphorIcon?: PhosphorIconT;
    active?: boolean;
    hidden?: boolean;
};

interface NavLinkItem extends NavItemBase {
    url: string;
    newTab?: boolean;
    prefetch?: boolean;
    className?: string;
}

/** A link in the side nav that directs to a specific page */
export const NavLink = ({
    label,
    PhosphorIcon,
    url,
    newTab,
    active,
    prefetch,
    className
}: NavLinkItem): JSX.Element => (
    <StyledLink
        href={url}
        target={newTab ? "_blank" : undefined}
        active={active}
        prefetch={prefetch}
        className={className}
    >
        {PhosphorIcon && (
            <PhosphorIcon size={20} weight={active ? "duotone" : undefined} />
        )}
        <span>{label}</span>
        {newTab && <ArrowSquareOut className="ml-auto" size={18} />}
    </StyledLink>
);

interface NavFolderItem extends NavItemBase {
    subItems: NavListItem[];
}

export type NavListItem = NavLinkItem | NavFolderItem;

/** Returns true if a NavListItem is a NavLinkItem (typeguard) */
export const isNavLink = (item: NavListItem): item is NavLinkItem =>
    "url" in item;

/** A folder in the side nav, containing links to more pages */
const NavFolder = ({
    label,
    PhosphorIcon = Folder,
    active,
    subItems
}: NavFolderItem): JSX.Element => {
    const [isOpen, setIsOpen] = useState(active);
    return (
        <>
            <StyledLink
                href="/"
                onClick={e => {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                }}
                prefetch={false}
                className="cursor-pointer"
            >
                <PhosphorIcon
                    size={20}
                    weight={active ? "duotone" : undefined}
                />
                <span>{label}</span>
                <CaretDown
                    size={18}
                    className={twMerge(
                        "ml-auto transition-all",
                        isOpen && "rotate-180"
                    )}
                    weight="bold"
                />
            </StyledLink>
            <Transition
                as="div"
                show={isOpen}
                enter="ease-in-out duration-300"
                enterFrom="max-h-0 overflow-hidden"
                enterTo="max-h-96 overflow-hidden"
                leave="ease-in-out duration-300"
                leaveFrom="max-h-96 overflow-hidden"
                leaveTo="max-h-0 overflow-hidden"
            >
                <NavLinkList items={subItems} isSubList />
            </Transition>
        </>
    );
};

export type NavLinkListProps = {
    items: NavListItem[];
    isSubList?: boolean;
    className?: string;
};

/** A stack of side nav links and folders, defined by the items array */
export const NavLinkList = ({
    items,
    isSubList,
    className
}: NavLinkListProps): JSX.Element => (
    <div
        className={twMerge(
            "flex flex-col",
            !isSubList
                ? "gap-y-1.5"
                : "gap-y-1 ml-4 pl-4 border-l border-solid border-nav-brand",
            className
        )}
    >
        {items.map(item => {
            if (item.hidden) return null;
            return isNavLink(item) ? (
                <NavLink key={`nav-link-${item.label}`} {...item} />
            ) : (
                <NavFolder key={`nav-folder-${item.label}`} {...item} />
            );
        })}
    </div>
);
