import { useRouter } from "next/router";
import React from "react";
import { useSession } from "next-auth/react";
import { twMerge } from "tailwind-merge";
import { URI_LOGIN } from "constants/urls";
import { NavTab } from "lib/types";
import { SideNav } from "./common/v2/SideNav/SideNav";

type AsideNavigation = {
    groupName: string;
    items: NavTab[];
};

// Opt out from static page optimizations
export async function getServerSideProps() {
    return { props: {} };
}

type AsideProps = {
    isOpen: boolean;
    hideNavbar: () => void;
    // Used to make Aside compatible with LayoutV2. Once we've fully deprecated Layout in favor of LayoutV2, we should apply this props changes permanently and remove this prop
    layoutV2?: boolean;
};

export default function Aside({
    isOpen,
    hideNavbar,
    layoutV2 = false
}: AsideProps) {
    const router = useRouter();
    const { data: session } = useSession();
    const isImpersonating = session?.isImpersonating;

    return router.pathname !== URI_LOGIN ? (
        <aside
            id="side-menu"
            className={twMerge(
                isImpersonating && !layoutV2 && "lg:pt-[65px]",
                layoutV2 ? "left-0 lg:static h-full" : "h-[100dvh]",
                "fixed top-0 transition-transform duration-300 lg:transform-none lg:opacity-100 z-30 w-[340px] lg:w-64",
                !isOpen && "-translate-x-[340px]"
            )}
        >
            <SideNav isOpen={isOpen} hideNavbar={hideNavbar} />
        </aside>
    ) : null;
}
