import { useContext } from "react";
import {
    Buildings,
    ChalkboardTeacher,
    Chats,
    CurrencyCircleDollar,
    Envelope,
    Folder,
    House,
    Notebook,
    PhoneIncoming,
    Question,
    UserCircle,
    UserList,
    ClipboardText,
    CheckSquareOffset,
    CalendarBlank
} from "phosphor-react";
import { NavListItem, isNavLink } from "./NavLinkList";
import {
    URI_BASE,
    URI_CLIENTS,
    URI_COMMISSIONS,
    URI_COMMUNITY,
    URI_EVENTS,
    URI_FORA_EMAIL,
    URI_GET_STARTED,
    URI_HELP_PORTAL,
    URI_HOME,
    URI_LEADS,
    URI_MARKETING,
    URI_ONBOARDING,
    URI_PARTNERS,
    URI_PARTNERS_SUPPLIERS,
    URI_PAYMENTS,
    URI_POLICIES,
    URI_RESOURCES,
    URI_TRAINING_V2,
    URI_USER_PROFILE,
    URI_WHATS_NEW
} from "constants/urls";
import { BookingPlatformViewModes } from "lib/types/supplier-database.types";
import { StoreContext } from "store";
import { pathToRegexp } from "path-to-regexp";
import { useAuth } from "hooks/use-auth";
import { useGetStartedEnabled } from "hooks/useGetStartedEnabled";

/** Returns true if the given url matches that of the window */
export const isLinkActive = (url: string): boolean =>
    pathToRegexp(url.replace(/\?.+/, ""), [], {
        sensitive: true,
        end: window.location.pathname === URI_HOME
    }).test(window.location.pathname);

/**
 * Assigns active to true or false for the given item.
 * If the given item already has active set, returns immediately.
 * If the given item is a link, assigns active based on a comparison to the current URL.
 * If the given item is a folder, recursively assigns active to its sub-items,
 * and assigns itself true if any of them are active.
 */
const setIsActive = (item: NavListItem): void => {
    if (item.active !== undefined) return;
    if (isNavLink(item)) {
        item.active = isLinkActive(item.url);
    } else {
        item.active = false;
        item.subItems.forEach(subItem => {
            setIsActive(subItem);
            if (subItem.active) item.active = true;
        });
    }
};

/** Returns curated lists of nav items for the primary Advisor Portal SideNav */
export const useNavItems = (): {
    top: NavListItem[];
    bottom: NavListItem[];
} => {
    const { isFeatureEnabled } = useAuth();

    const showGetStarted = useGetStartedEnabled();

    const showPaymentsPage = isFeatureEnabled(
        "payments_page",
        "show_on_navtab"
    );
    const showEvents = isFeatureEnabled("calendar_events");
    const showLeadsPage = isFeatureEnabled("leads_program");

    const { bookingFiltersURI } = useContext(StoreContext);
    const currentPath = window.location.pathname;

    const top: NavListItem[] = [
        {
            label: "Get Started",
            PhosphorIcon: CheckSquareOffset,
            url: URI_GET_STARTED,
            hidden: !showGetStarted,
            active:
                currentPath === URI_BASE ||
                currentPath.includes(URI_GET_STARTED)
        },
        {
            label: "Home",
            PhosphorIcon: House,
            url: URI_HOME,
            active:
                (!showGetStarted && currentPath === URI_BASE) ||
                currentPath === URI_HOME ||
                currentPath.includes(URI_ONBOARDING)
        },
        {
            label: "Clients",
            PhosphorIcon: UserList,
            url: URI_CLIENTS
        },
        {
            label: "Leads",
            PhosphorIcon: PhoneIncoming,
            url: URI_LEADS,
            hidden: !showLeadsPage
        },
        {
            label: "Partners",
            PhosphorIcon: Buildings,
            url: `${URI_PARTNERS}?view_mode=${
                BookingPlatformViewModes.LIST
            }&supplierType=hotels${
                currentPath.includes(URI_PARTNERS) ? "&clearAll=true" : ""
            }`,
            active: isLinkActive(URI_PARTNERS_SUPPLIERS)
        },
        {
            label: "Bookings",
            PhosphorIcon: Notebook,
            url: bookingFiltersURI || URI_COMMISSIONS
        },
        {
            label: "Payments",
            PhosphorIcon: CurrencyCircleDollar,
            url: URI_PAYMENTS,
            hidden: !showPaymentsPage
        },
        {
            label: "Training",
            PhosphorIcon: ChalkboardTeacher,
            url: URI_TRAINING_V2
        },
        {
            label: "Events",
            PhosphorIcon: CalendarBlank,
            url: URI_EVENTS,
            hidden: !showEvents
        },
        {
            label: "Resources",
            PhosphorIcon: Folder,
            subItems: [
                { label: "Marketing", url: URI_MARKETING },
                { label: "Templates & Forms", url: URI_RESOURCES },
                { label: "What's New", url: URI_WHATS_NEW }
            ]
        },
        {
            label: "Policies",
            PhosphorIcon: ClipboardText,
            url: URI_POLICIES
        },
        {
            label: "Help",
            PhosphorIcon: Question,
            url: URI_HELP_PORTAL
        }
    ];
    top.forEach(setIsActive);

    const bottom: NavListItem[] = [
        {
            label: "Fora Email",
            PhosphorIcon: Envelope,
            url: URI_FORA_EMAIL,
            newTab: true
        },
        {
            label: "Forum",
            PhosphorIcon: Chats,
            url: URI_COMMUNITY,
            newTab: true
        },
        {
            label: "Account",
            PhosphorIcon: UserCircle,
            url: URI_USER_PROFILE
        }
    ];
    bottom.forEach(setIsActive);

    return { top, bottom };
};
