import { useAuth } from "./use-auth";


/** Returns true if the user has the Get Started page enabled */
export const useGetStartedEnabled = (): boolean => {
    const { isFeatureEnabled, userProfile } = useAuth();
    const hasGettingStartedFF = isFeatureEnabled("getting_started");
    const getStartedHidden = userProfile?.hide_onboarding;

    return hasGettingStartedFF && !getStartedHidden;
};
